<template>
  <div class="file " style="display: flex; flex: 1">
    <div class="file-view flex-column" style="display: flex; flex: 1">
      <v-row no-gutters class="mt-3">
        <v-col>
          <div class="file-view__name">
            {{ item.name }}
          </div>
          <div class="file-view__date">
            <div v-if="item[id]">
              <span @click="getFile" class="file-link mr-4">
                <v-progress-circular
                    v-if="uploadNow"
                    indeterminate
                    color="primary"
                    size="14"
                    width="2"
                ></v-progress-circular>{{ item.originalFileName ? item.originalFileName : 'Загруженный файл' }}</span>
              <template v-if="uploadFileDate"> Дата загрузки: {{ uploadFileDate }}</template>
            </div>
            <div v-else class="error--text">
              <slot name="emptyFile">Не загружено</slot>
            </div>
          </div>
        </v-col>
        <v-col cols="2">
          <div class="file-view__actions d-flex flex-row justify-end align-center">
            <div class="d-flex flex-column align-end">
<!--              <p class="mb-0 pb-0">открыть загрузку файлов</p>-->
              <v-btn
                  icon
                  :disabled="disabled"
                  @click="modeEdit = !modeEdit"
              >
                <v-icon color="primary" size="25">mdi-folder-open-outline</v-icon>
              </v-btn>
            </div>
            <div class="d-flex flex-column align-center">

<!--              <p class="mb-0 pb-0"> &nbsp;</p>-->
              <slot name="deleteBtn" :disabled="disabled" :deleteFile="deleteFile">
                <v-btn
                    v-if="canDelete"
                    icon
                    :disabled="disabled"
                    @click="deleteFile()"
                >
                  <v-icon color="error">mdi-delete-outline</v-icon>
                </v-btn>
              </slot>
            </div>
          </div>

        </v-col>
      </v-row>
      <v-row v-show="modeEdit && !disabled" no-gutters>
        <v-col>
          <v-file-input
              clearable
              label="Выбрать файл..."
              v-model="fileToUpload"
              :accept="typesFiles"
              :error="error"
              :error-messages="errorMessage"
              :disabled="disabled || uploadToServNow"
              :loading="uploadToServNow"
              prepend-icon=""
              :rules="rules"
              @change="errUpd($event)"
          />
        </v-col>
        <v-col cols="2" class="d-flex flex-row align-center justify-end">
          <div class="d-flex flex-column align-end">
<!--            <p class="mb-0 pb-0">  загрузить скан</p>-->
<!--            <v-btn-->
<!--                icon-->
<!--                :disabled="!canUpload || uploadToServNow"-->
<!--                :loading="uploadToServNow"-->
<!--                @click="uploadFile"-->
<!--            >-->
<!--              <v-icon color="success" size="25">mdi-file-upload-outline</v-icon>-->
<!--            </v-btn>-->
          </div>
          <div class="d-flex flex-column align-center">
<!--            <p class="mb-0 pb-0"> &nbsp;</p>-->
            <v-btn
                icon
                :disabled="!canUpload || uploadToServNow"
                @click="clearForm"
            >
              <v-icon color="error">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>


  </div>
</template>

<script>
// import request from "@/services/request";
import {saveBlobFile} from "@/utils/saveFile";
import axios from "axios";
import {mapGetters} from "vuex";
export default {
  name: "scanCard",
  props: {
    item: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    uploadToServNow: {
      type: Boolean,
      default: false
    },
    token: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: 'id'
    },
    identifier:{
      type: String,
      default: 'identifier'
    }
  },
  data() {
    return {
      fileToUpload: null,
      modeEdit: false,
      uploadNow: false,
      error: false,
      errorMessage: '',

      canUpload: true,
    }
  },
  computed: {
    rules(){
      return [
        value => ((value != null) || !!this.item?.id) || 'Выберите файл',
        value => ((value?.size < 20000000) || !!this.item?.id) || 'Размер файла не должен превышать 20 МБ!',
      ]
    },
    uploadFileDate() {
      return this.item?.uploadDate?.slice(0, 10) ?? ''
    },
    typesFiles() {
      if (this.item.types === 'all')
        return null
      return this.item.types.join(',')
    },
    ...mapGetters('keycloak',{getAuthData:'getAuthData'}),
    calculatedToken(){
      return this.token ? this.token : this.getAuthData?.token
    }
  },
  methods: {
    errUpd(evt) {
      if (evt && evt.size < 20000000) {
        this.error = false;
        this.errorMessage = '';
        this.uploadFile()
      }
    },
    clearForm() {
      this.modeEdit = false;
      this.fileToUpload = null;
      this.canUpload = true;
      this.error = false;
      this.errorMessage = '';
    },
    uploadFile: function () {
      if (this.fileToUpload?.size < 20000000) {
        this.error = false;
        this.errorMessage = '';
        if (this.fileToUpload) {
          if (this.item[this.id]) {
            this.$emit('replace-file', {
              [this.identifier]: this.item[this.identifier],
              'file': this.fileToUpload,
              'id': this.item[this.id]
            });
          } else {
            this.$emit('upload-file', {[this.identifier]: this.item[this.identifier], 'file': this.fileToUpload});
          }
        }
      } else {
        this.canUpload = false
        this.error = true;
        this.errorMessage = this.fileToUpload?.size ? 'Размер файла не должен превышать 20 МБ!' : 'Выберите файл'
      }
    },
    async deleteFile() {
      if (this.item[this.id]) {
        this.$emit('delete-file', {
          [this.identifier]: this.item[this.identifier],
          'file': this.fileToUpload,
          'id': this.item[this.id]
        });
      } else {
        this.fileToUpload = null;
      }
    },
    async getFile() {
      this.uploadNow = true;
      try {
        const response = await axios.get(`/api/files/${this.item[this.id]}`, {
          responseType: `blob`,
          headers: {Authorization: this.calculatedToken ? `Bearer ${this.calculatedToken}` : ''},
        })
        this.uploadNow = false;
        saveBlobFile(response.data);
      } catch (e) {
        this.uploadNow = false;
        console.log("e", e)
      }
    }
  },
}
</script>

<style scoped>

</style>