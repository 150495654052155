<template>
    <div class="table-grid">
        <div
                :style="`grid-template-columns : repeat(${numCols}, 1fr); max-height: ${maxTableHeight};`"
                class="table-grid__container"
        >
            <!--       header -->
            <div
                    :style="`grid-column: span ${numCols}; grid-template-columns : repeat(${numCols}, 1fr)`"
                    class="table-grid-header-row"
                    ref="table-grid-header-row"
            >
                <slot :headers="localHeaders" name="defaultHeader">
                    <div
                            :class="item.headerClass" :key="i"
                            :ref="'table-grid-header__cell-'+item.dataField"
                            :style="`grid-column: span ${(Number(item.cols) )}; grid-row: span ${Number(item.rows)};
               top: ${topFixedPositionForHeaderCell(item).offsetTop}px;`"
                            class="table-grid-header__cell"
                            v-for="(item, i) in localHeaders"
                    >
                        <slot :item="item" :name="'header-'+item.dataField">
                            <span>{{ item.text }}</span>
                            <v-tooltip
                                    color="#00599b"
                                    top
                                    v-if="item.headerTooltip != null && item.headerTooltip"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                            :size="15"
                                            class="btn__icon grey-light-color__important"
                                            v-bind="attrs"
                                            v-on="on"
                                    >
                                        mdi-information
                                    </v-icon>
                                </template>
                                <p class="white--text" v-html="item.headerTooltipText()"></p>
                            </v-tooltip>
                        </slot>
                    </div>
                </slot>
                <slot :headers="headers" name="customHeader">

                </slot>
            </div>
            <!--        body-->
            <template v-if="loadingData">
                <div :style="`grid-column: span ${numCols}; min-height: 200px;`" class="d-flex flex-row align-center">
                    <v-progress-linear indeterminate></v-progress-linear>
                </div>
            </template>
            <template v-else-if="dataRows.length === 0">
                <div :style="`grid-column: span ${numCols}; grid-template-columns : repeat(${numCols}, 1fr);`"
                     class="table-grid-body-row"
                >
                    <div :style="`grid-column: span ${(numCols )};  min-height: 200px!important;`"
                            class="table-grid-body-row__cell"
                    >
                        Нет данных
                    </div>
                </div>
            </template>
            <template v-else>
                <div :key="(row.id != null ? row.id : j)"
                     :ref="'table-grid-body-row__'+(row.id != null ? row.id : j)"
                     :style="`grid-column: span ${numCols}; grid-template-columns : repeat(${numCols}, 1fr)`"
                     @click="rowAction($event,row)"
                     class="table-grid-body-row"
                     v-for="(row, j) in dataRows"
                >
                    <div
                            :class="[cell.class, painCell(row, cell)]"
                            :key="cell.dataField"
                            :style="`grid-column: span ${(Number(cell.cols) )}; ` + `${cell.styles}`"
                            class="table-grid-body-row__cell"
                            v-for="(cell) in headersForRows"
                    >
                        <!--           grid-template-columns: minmax(200px, ${(numCols )}fr); -->
                        <slot :cell="cell" :id="row.id != null ? row.id : j" :name="cell.dataField" :row="row">
                            <template v-if="!cell.component">
                                {{ cell.template ? cell.template(row) : row[cell.dataField] }}
                            </template>
                        </slot>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

    export default {
        name: "GridTable",
        props: {
            headers: {
                type: [Array],
                default: () => []
            },
            numCols: {
                type: [Number, String],
                default: 0
            },
            maxTableHeight: {
                type: [String, Number],
                default: '550px',

            },
            loadingData: {
                type: Boolean,
                default: false
            },
            dataRows: {
                type: Array,
                default: () => []
            },
            paintBackground: {
                type: [Function],
                default: () => {
                }
            },
            importsComponents: {
                type: [Array, Object]
            }
        },
        computed: {
            headersForRows() {
                return this.headers.filter(el => el.variable).sort((a, b) => a.order - b.order)
            },
        },
        data() {
            return {
                localHeaders: this.headers
            }
        },
        mounted() {
            this.setTopPositions();
            // window.addEventListener('resize', this.setTopPositions)
        },
        beforeDestroy() {
            // window.removeEventListener('resize', this.setTopPositions)
        },
        methods: {
            rowAction(e, row) {
                this.$emit('row-action', {e: e, row: row})
            },
            setTopPositions() {
                this.localHeaders = this.localHeaders.map(el => {
                    const offsetHeight = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.offsetHeight : '';
                    const offsetTop = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.offsetTop : '';
                    const clientHeight = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.clientHeight : '';
                    const clientWidth = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.clientWidth : '';
                    return {
                        ...el,
                        offsetHeight: offsetHeight,
                        offsetTop: offsetTop - 2,
                        clientHeight: clientHeight,
                        clientWidth: clientWidth
                    }
                })
            },
            topFixedPositionForHeaderCell(cell) {
                return this.localHeaders.length > 0 ? this.localHeaders.find(el => el.dataField === cell.dataField) : ''
            },
            // eslint-disable-next-line no-unused-vars
            painCell(row, cell) {
                if (this.paintBackground(row))
                    return this.paintBackground(row)();
            }
        },
    }
</script>

<style scoped>

</style>