/**
 * @param str string | number | null | undefined строка
 * @param unit string размерность default px
 * @return string | undefined
 * */
export function convertToUnit (str,  unit = 'px') {
    if (str == null || str === '') {
        return undefined
    } else if (Number.isNaN(str)) {
        return String(str)
    } else {
        return `${Number(str)}${unit}`
    }
}

export function mapSpr(spr = [], idName = '', tempFunc = () => {
    return ''
}) {
    const buf = [...spr];
    if (buf.length === 0) return buf;
    return buf.map(el => {
        return {value: el[idName], text: tempFunc(el)}
    })
}