import request from "@/services/request";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import {saveFile} from "@/utils/saveFile";

export const setFileFunctions =  {
    data(){
        return {
            file: {
                name: 'Подтверждающие документы',
                types: ['application/pdf'],
            },
            loadingFileToServer: {
                value: false,
                fileType: ''
            },
        }
    },
    computed: {
        ...mapGetters('keycloak', {getAuthData: 'getAuthData'}),
        token() {
            return this.getAuthData.token
        },
    },
    methods:{
        ...mapActions('notifications', {addNotification:'addNotification'}),
        getFile() {
           throw new Error(
               ` add Function  getFile() {
      this.file = {...this.file, id: this.$attrs.value.fileId, fileType: 'ORG_APPEAL' };
    },`
           )
        },
        async uploadFile(event) {
            this.loadingFileToServer.value = true;
            this.loadingFileToServer.fileType = event.fileType;
            let fd = new FormData();
            fd.append('file', event.file);
            try {
                const res = await request({
                    endpoint: `/api/files/?fileType=${event.fileType}`,
                    method: 'post',
                    body: fd,
                });
                this.file = {...this.file, ...res.data};
                this.loadingFileToServer.value = false;
            } catch (e) {
                this.loadingFileToServer.value = false;
                await this.addNotification({
                    notificationStatus: 'error',
                    notificationMessage: 'Ошибка загрузки',
                    timeout: 3000
                })
            }
        },
        async downloadFile(id, api, isBlob = false, blobFileName = '', loaderFlag = null) {
            if (loaderFlag === null) {
                this.downloadNow = true;
            } else {
                this[loaderFlag] = true;
            }
            try {
                const response = await axios.get(`${api ? api: `/api/files/${id}`}`, {
                    responseType: `${isBlob ? 'blob' : 'json'}`,
                    headers: {Authorization: this.token ? `Bearer ${this.token}` : ''},
                })
                if (loaderFlag === null) {
                    this.downloadNow = false;
                } else {
                    this[loaderFlag] = false;
                }
                if (isBlob){
                    const blob = new Blob([response.data], {type: "application/pdf"});
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = blobFileName;
                    link.click();
                    link.remove();
                } else {
                    saveFile({...response.data, fileBytes: response.data.file}, false);
                }
            } catch (e) {
                await this.addNotification({
                    notificationStatus: 'error',
                    notificationMessage: 'Ошибка скачивания ' +e,
                    timeout: 3000
                });
                if (loaderFlag === null) {
                    this.downloadNow = false;
                } else {
                    this[loaderFlag] = false;
                }
                console.log("e", e)
            }
        },
        async deleteFile(event) {
            try {
                const response = await request({
                    endpoint: `/api/files/${event.id}`,
                    method: 'DELETE',
                    token: this.token,
                });
                if (response.status === 200) {
                    this.file = {
                        name: this.file.name,
                        fileType: event.fileType,
                        types: this.file.types
                    }
                }
            } catch (e) {
                await this.addNotification({
                    notificationStatus: 'error',
                    notificationMessage: 'Ошибка удаления',
                    timeout: 3000
                });
                console.log("e delete", e)
            }
        },
        async replaceFile(event) {
            await this.deleteFile(event)
            await this.uploadFile(event)
        },
    }
}